@import '../../../../style/varibale';

.p-button.p-button-warning,
.p-buttonset.p-button-warning>.p-button,
.p-splitbutton.p-button-warning>.p-button,
.p-fileupload-choose.p-button-warning {
    color: #ffffff;
    background: $mainColor !important;
    border: 1px solid $mainColor !important;
}

.app__cars {
    .Taps {
        display: flex;
        flex-direction: row; 
        // position: relative;
        .btn1,
        .btn2,
        .btn3 {
            @media screen and (width <=767px) {
                display: none;
            }

            margin-top: -25px;

            .p-button {
                width: 220px !important;
                border-radius: 0 !important;
                border-color: white !important;
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);


            }
        }

        .btn4,
        .btn5 {
            .p-button {
                border-color: white !important;
                width: fit-content !important;

                @media screen and (width <=767px) {
                    padding: 11px 13px !important;
                }
            }
        }
    }

    .app__title {
        h3 {
            // font-size: 16px;
        }
    }


    .taps_sm {

        visibility: hidden;

        @media screen and (width <=767px) {
            visibility: visible;
        }

        .p-dropdown {
            border: $mainColor;
            background-color: $mainColor !important;
            border-radius: 20px;
            display: inline-flex;
            padding: 0px;
            justify-content: center;
            align-items: center;
            gap: 0px;
            width: 210px !important;

            @media screen and (width <=550px) {
                width: 150px !important;
            }

            span {
                color: var(--white, #FFF);
                font-family: Cairo;


                font-style: normal;
                font-weight: 400;
                line-height: 170.4%;

                @media screen and (width<=440px) {
                    font-size: 10.016px;
                }
            }
        }

        .p-dropdown-label-empty {
            visibility: visible;
        }
    }

    .p-dropdown {
        background-color: $dark !important;

        span {

            color: white !important;
        }

        .p-dropdown-trigger {
            svg {
                path {
                    fill: #fff !important
                }
            }
        }
    }
}

.app__filters_car {
    .p-button-info {
        background-color: white !important;
        color: $dark !important;
    }
}

.active {
    .p-button {
        background-color: $dark !important;
        border-radius: 0 !important;
        border-color: white !important;
        width: fit-content !important;
        color: white !important;
    }
}

.button-warning {
    color: #ffffff;
}

.Form1 {

    .p-inputtext,
    .p-button {
        background-color: $dark !important;
        color: white !important;
        border-color: white !important;
    }

    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $dark !important;
        background: $dark !important;
    }

    .p-calendar input::placeholder {
        color: white !important;
    }

    .p-calendar input::placeholder {
        direction: rtl !important;
    }

    .p-calendar input::placeholder {
        border-color: #ccc;
    }

    .p-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #C7D2FE;
    }

    .text_box {
        color: $dark;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
    }
}

.Form2 {
    .p-button {
        background-color: $dark !important;
        color: white !important;
        border-color: white !important;
        border-radius: 15px !important;
    }
}

.Form4 {
    .Taps {
        display: flex;
        flex-direction: row;

        /*     .p-button-label {
            background-color: red !important;
        }
 */
        .btn4,
        .btn5 {
            margin-top: -25px;

            .p-button {
                // background-color: $mainColor !important;
                border-radius: 0 !important;
                border-color: white !important;
                width: fit-content !important;
            }
        }

        .active {
            .p-button {
                background-color: $dark !important;
                border-radius: 0 !important;
                border-color: white !important;
                width: fit-content !important;
            }
        }
    }
}

.btn4 {
    .p-button {
        border-radius: 0px 20px 20px 0px !important;
    }

    .p-button-label {
        font-weight: 400 !important;
    }
}

.btn5 {
    .p-button {
        border-radius: 20px 0px 0px 20px !important;
    }

    .p-button-label {
        font-weight: 400 !important;
    }
}

.Form3 {
    .p-button {
        background-color: $dark !important;
        border-color: $dark !important;
    }

    .p-dropdown {
        background-color: white !important;
        color: $dark !important;

        span {
            color: $dark !important;
        }

        .p-dropdown-trigger {
            svg {
                path {
                    fill: $dark !important
                }
            }
        }
    }

}

.calendar_styleAr {
    .p-calendar .p-inputtext {
        text-align: end !important;
    }
}

.grid_update_20 {
    .col-xl-3 {
        flex: 0 0 auto;
        width: 20% !important;

        @media ( width < 1240px) {
            width: 25% !important;
        }
        @media ( width < 1000px) {
            width: 50% !important;
        }
      
        @media (max-width: 768px) {
            width: 50% !important;

        }
        @media (width < 646px) {
            width: 100% !important;

        }

        
    }
}