@import '../../style/varibale';

.app__footer {
    background-color: $dark;

    .social {
        display: flex;
        gap: 25px;
 
        position: relative; 

        svg,
        .facebook {
            width: 16px !important;
            height: 16px !important;
        }



    }

    .footer_Links {
        h1 {
            color: $mainColor; 
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .footer_ui {
            ul {
                display: flex;
                gap: 16px;
                margin-top: 20px !important;
                flex-direction: column;

                li {
                    color: white;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    cursor: pointer;
                    line-height: 197.4%; 
                    letter-spacing: 0.63px;
                    a{
                        color: #fff !important;
                        
                    }

                    
                    .active_main_color{
                        color: rgba($color: $mainColor, $alpha: 0.8) !important;
                    }
                }
            }
        }
    }
}

.hr__footer {
    margin-top: 40px;
    color: white;
}

.footer__buttom {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        color: var(--white, #FFF); 
          font-family: Cairo;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; 
        opacity: 0.5;

        @media screen and (width < 500px) {
            font-size: 12px;
        }
    }
}

 .gap-sm-8{
    @media screen and (width < 980px) {
        
        gap: 50px;
    }
 }

 .payment_icons{
    svg{
        width:40px;
        height:40px;
    }
 }