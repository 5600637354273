@import '../../../style/varibale';

[dir="ltr"] .p-menubar .p-menubar-end {
    margin-left: auto !important;
    align-self: center !important;
}

[dir="rtl"] .p-menubar .p-menubar-end {
    margin-right: auto !important;
    align-self: center !important;
    margin-left: 0% !important;
}

.navbar-primereact {

    .p-menubar-root-list {}

    .p-menubar {
        border-radius: 0 !important;
    }
}

@media screen and (max-width: 960px) {
    .p-menubar .p-menubar-button {
        display: flex;
        width: 2rem;
        height: 2rem;
        color: #6c757d;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        display: none !important;
    }

}

.app__navbar-menu {
    // width: 35px;
    // height: 35px;
    // border-radius: 50%;
    // z-index: 2 !important;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // cursor: pointer;
    // background-color: $mainColor;

    svg {
        width: 70%;
        height: 70%;
        color: $colorLight;
    }

    // @media screen and (min-width:900px) {
    //     display: none;
    // }
}

.navbar-primereact {
    .p-menubar {
        background-color: $dark;
        border: $dark;
        height: 70px;
    }

    .p-menubar-start {
        .p-button {
            background-color: $mainColor ;
            color: #fff;
        }
    }

}

.nabar_side {
    a {
        color: #FFF;
        font-family: cairo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 197.4%;
        letter-spacing: 0.63px;

        &:hover {
            color: $mainColor;
        }

    }
}

.nabar_side-a {
    @media screen and (width <=912px) {
        display: none !important;
    }
}

.username {
    margin-top: 30px !important;
}


.SunNavbar2 {
    @media screen and (width >=912px) {
        display: flex !important;
    }

    @media screen and (width <=912px) {
        display: none !important;
    }
}

.SunNavbar {
    @media screen and (width >=912px) {
        display: none !important;
    }

    @media screen and (width <=912px) {
        display: flex !important;
    }
}

@media screen and (width <=912px) {
    .p-menubar-start {
        width: 100%;
    }
}

.navbar-primereact-sm {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 15px !important;

    &:hover {
        svg {

            fill: $mainColor;
            stroke: $mainColor;
        }
    }
}

#popup_menu {
    background-color: $dark !important;
    width: 300px !important;
}

.p-tieredmenu {

    .p-tieredmenu-overlay {
        background: $dark !important;
        border: 0 none;
        color: #fff !important;
    }

    .p-menuitem.p-menuitem-active>.p-menuitem-link {
        background: $mainColor !important;
    }

    .p-menuitem-text {
        a {
            svg {
                stroke: #fff !important;
            }

            color: white !important;
        }

        .p-menuitem-icon {
            background-color: white !important;
        }
    }

    .details_users {
        span {
            color: white;
        }

        &:hover {
            background-color: $mainColor !important;
        }
    }

    .p-menuitem {
        background-color: $dark !important;
    }

    .p-menuitem-link .p-menuitem-icon {
        color: #fff !important;
        margin-right: 0.5rem;
    }
    .p-menuitem-text{
        color: #fff !important;

    }
}