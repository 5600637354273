@import '../../../../style/varibale';

.app__home__offers {
    img {
        border-radius: 15px !important;
        object-fit: cover;
    }

    .app__btn {
        .p-button {
            background-color: $dark !important;
            border-color: $dark !important;
            padding: 16px 48px !important;
            border-radius: 20px !important;
            width: 100% !important;
        }
    }

    .img__offer {
        position: relative;

        .content {
            position: absolute;
            bottom: 15px;


            h1 {
                color: var(--white, #FFF);
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border-radius: 50px;
                padding: 0 10px;
            }


            p {
                color: var(--white, #FFF);
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 170.4%;
            }
        }
    }
}

.img__offer_lg {
    height: 599px;

    @media screen and (width <=1400px) {
        height: 520px;
    }

    @media screen and (width <=1200px) {
        height: 440px;
    }
}

.img__offer_sm {
    height: 291px;

    @media screen and (width <=1400px) {
        height: 249px;
    }

    @media screen and (width <=1200px) {
        height: 207px;
    }

    @media screen and (width <=992px) {
        height: 318px;
    }

    @media screen and (width <=767px) {
        height: 235px;
    }
}

.offer_column_reverse {
    @media screen and (width <=988px) {
        display: flex !important;
        flex-direction: column-reverse !important;
    }
}

.app__home__offers .img__offer .content p {
    @media screen and (width <=500px) {
        font-size: 10px;
        font-weight: 700;
    }
}

.app__btn_offer_column_reverse2 {
    display: none !important;

    @media screen and (width <=988px) {
        display: flex !important;
    }
}

.app__btn_offer_column_reverse {
    display: flex !important;

    @media screen and (width <=988px) {
        display: none !important;
    }
}

[dir="rtl"] .offer_column_reverse .content {
    right: 0px;
}

[dir="ltr"] .offer_column_reverse .content {
    left: 0px;
}

[dir="rtl"] .offer_column_reverse .content h1,
.offer_column_reverse .content p {
    text-align: right;

    @media screen and (width < 767px) {
        font-size: 10px;
    }
}

.offer_column_reverse .content h1 {

    @media screen and (width < 767px) {
        font-size: 13px !important;
    }
}

.offer_column_reverse .content p {

    @media screen and (width < 767px) {
        font-size: 10px !important;
    }
}

[dir="ltr"] .offer_column_reverse .content h1 {
    text-align: left;
    margin-left: 13px;
}

[dir="ltr"] .offer_column_reverse .content p {
    text-align: left;
    margin-left: 25px;
}

[dir="rtl"] .offer_column_reverse .content {
    background: linear-gradient(276deg, #2D3D55 0%, rgba(45, 61, 85, 0.00) 87.80%, rgba(45, 61, 85, 0.00) 100%) !important;

}

[dir="ltr"] .offer_column_reverse .content {
    background: linear-gradient(-276deg, #2D3D55 0%, rgba(45, 61, 85, 0.00) 87.80%, rgba(45, 61, 85, 0.00) 100%) !important;
}