@import '../../style/varibale';

.success-wrapper,
.cancel-wrapper {
  background-color: white;
  min-height: 60vh;

}

.success_point {
  width: fit-content;
  margin: auto;
  margin-top: 100px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;

  svg {
    width: 100%;
  }

}

.success,
.cancel {
  width: 60%;
  margin: auto;
  margin-top: 160px;
  background-color: #dcdcdc;
  padding: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  @media screen and (width < 1215px) {
    width: 60%;
  }

  @media screen and (width < 1011px) {
    width: 80%;
  }

  @media screen and (width < 688px) {
    width: 95%;
  }

  @media screen and (width < 600px) {
    padding: 50px 20px;
  }
}

.success .icon {
  svg {
    color: green;
    width: 40px;
    height: 40px;
  }
}

.success h2 {
  text-transform: capitalize;
  margin-top: 15px 0px;
  font-weight: 900;
  font-size: 40px;
  color: #324d67;

  @media screen and (width < 767px) {
    font-size: 30px;
  }

  @media screen and (width < 600px) {
    font-size: 20px;
  }
}

.success .email-msg {
  font-size: 16px;
  font-weight: 600;
  text-align: center;

  @media screen and (width < 600px) {
    margin-top: 14px;
  }
}

.cancel p {
  font-size: 20px;
  font-weight: 600;
}


.success_btn {
  width: 400px;
  margin: auto;
}

.success_btn {
  width: 100%;
  max-width: 400px;
  padding: 10px 12px;
  border-radius: 15px;
  border: none;
  font-size: 20px;
  margin-top: 10px;
  margin-top: 40px;
  text-transform: uppercase;
  background-color: $mainColor !important;
  color: #fff;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.success_btn:hover {
  transform: scale(1.1, 1.1);
}


.success .description {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 10px;
  margin-top: 30px;

  @media screen and (width < 600px) {
    font-size: 14px;
  }
}

.success .description .email {
  margin-left: 5px;
  color: $mainColor;
}

.order_success {
  h2 {}
}

.order_success h2 {
  text-transform: capitalize;
  margin-top: 15px 0px;
  font-weight: 900;
  font-size: 30px;
  color: #324d67;

  @media screen and (width < 767px) {
    font-size: 30px;
  }

  @media screen and (width < 600px) {
    font-size: 20px;
  }
}


.invoice {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 25px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.invoice span {
  display: block;
  margin-bottom: 10px;
}

.invoice .label {
  font-weight: bold;
}

.invoice .amount {
  color: green;
  font-weight: bold;
}