@import '../../../style/varibale';

.app_blogs {
    margin-top: 140px;
}


.app_blog_details {
    width: 75%;
    margin: auto;

    margin-bottom: 80px;

    h1 {
        font-family: $fontBase;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
        color: $mainColor;


    }

    p {
        font-family: $fontBase !important;

        font-size: 18px !important;
        font-weight: 400;
        line-height: 30px;

        @media screen and (width < 900px) {
            text-align: justify;
            width: 95%;
        }
    }

    @media screen and (width < 900px) {
        width: 95%;
    }

    img {
        border-radius: 10px;
        width: 100%;
    }

    .introduction {
        img {
            height: 450px;
            object-fit: cover;
        }

        .header {
            margin-top: 90px;

            h1 {
                font-family: $fontBase;
                font-size: 30px;
                font-weight: 600;
                line-height: 38px;
                color: $mainColor;



            }
        }

        .content {

            ul,
            ol {
                list-style: none;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                li {

                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    color: $dark;

                    opacity: 0.6;

                }
            }
        }
    }

    .image_section {
        margin-top: 90px;


        span {
            display: block;
            margin-top: 25px !important;
            font-family: $fontBase;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $mainColor;

        }
    }

    .content_ceo {
        background-color: $dark;
        color: $dark;

        padding: 20px 0;
        position: relative;

        blockquote {
            font-family: 'Arial', sans-serif;
            font-size: 16px;
            line-height: 1.5;
            margin: 0;
            padding-left: 20px;
            position: relative;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        blockquote:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 3px;
            background-color: $mainColor;
        }

        footer {
            display: block;
            margin-top: 40px;
            font-size: 14px;
            font-style: italic;
            text-align: right;
            color: $dark;

            opacity: 0.7;

        }
    }

    .content_sw {
        margin-top: 70px;

        ul,
        ol {
            list-style: none;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            li {
                font-family: cairo;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;

                opacity: 0.8;

                @media screen and (width < 900px) {

                    font-size: 16px;
                }
            }
        }
    }

    .other_resources,
    .software_tools {
        margin-top: 52px;

        h2 {
            font-family: $fontBase;
            font-size: 30px;
            font-weight: 400;
            line-height: 38px;

            font-weight: 600;

            @media screen and (width < 900px) {
                font-size: 20px;
            }

        }

        p {
            margin-top: 18px;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;

            color: $dark;
            font-family: cairo;


        }

        ul,
        ol {
            list-style: decimal;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            padding: 0 40px;


        }
    }

    .footer_content_blogs {
        margin-top: 80px;

        span {
            display: block;
            margin-top: 25px !important;
            font-family: $fontBase;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: $mainColor;

        }
    }

    .conclusion_content {
        margin-top: 40px;

        h2 {
            font-family: $fontBase;
            font-size: 30px;
            font-weight: 600;
            line-height: 38px;

            @media screen and (width < 900px) {
                font-size: 25px;

            }
        }

        ol {
            list-style: decimal;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 0 30px;

        }
    }

    li {
        font-family: cairo;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;

        opacity: 0.8;

        @media screen and (width < 900px) {
            font-size: 16px;
        }
    }
}

.header_blog {
    .blog_image_0 {
        width: 100%;
        height: 315px !important;
        object-fit: cover;
    }
}

.title_blog { 
    margin-top: 20px; 

    h2 { 
        margin-bottom: 20px;
        font-weight: 400px; 
    }
}

.rtl {

    .blog_section {

        padding: 20px;
        width: 95%;
        margin: auto;

        .header_blog {
            position: relative;

            .blog_category {
                position: absolute;
                right: 30px;
                top: 30px;
                width: fit-content;
            }
        }

    }
}

.blog_card {
    background-color: transparent;
    border-radius: 10px;
    border: none;
    color: white;
    // padding: 20px;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.05);
    }



    .blog_category {
        padding: 5px 10px;
        color: white;
        display: inline-block;
        font-size: 14px;
        padding: 3px 8px;
        border-radius: 3px;
        background: rgba(0, 91, 246, 1);

    }



    h3 {
        color: white;
        font-size: 20px;
        margin-bottom: 10px;
    }

    p {
        color: #ccc;
        font-size: 14px;
    }
}
.blog_meta {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #ccc; 

    .blog_icon {
        margin-right: 5px;
    }

    i {
        color: $mainColor;
        font-size: 14px;
    }
    .blog_date {
        margin-right: 0px;
        color: rgba(108, 117, 125, 1);
        font-size: 14px !important;
    }
    h6{
        color: $dark;
    }
}
.blog_image {
    width: 100%;
    height: 284px !important;
    object-fit: cover;

}

.app_blog_details_img {
    margin-top: 80px;
    img {
        width: 100%;
        height: 350px;
        object-fit: cover;

        @media screen and (width < 767px) {
            height: 200px;

        }
    }

    position: relative;

    .overlay {
        position: absolute;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100% !important;
        background: linear-gradient(180deg, rgba(22, 42, 43, 0.51) 20%, rgba(22, 42, 43, 0.00) 100%);

    }
}

.tabs {
    i {
        display: inline-block;
        background-color: var(--offwhite);
        color: var(--black-100);
        padding: 10px 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        text-decoration: none;
        font-size: 12px;
        font-size: 0.75rem;
    }
}