@import '../../../style/varibale';

.app__filters_car {
    .card {
        border: 5px solid $dark !important;
    }

    .Sub_Taps {
        margin-right: 0px;
    }

    .btn__filter-car {
        .p-button {
            background-color: $mainColor !important;
            // border-radius: 50px 4px 4px 50px;
            flex-direction: row-reverse !important;
            align-items: center !important;
        }
    }


}

[dir="rtl"] .taps__serach .Taps {
    margin-right: 39px;

    @media screen and (width<=988px) {
        margin-right: 0px;

    }
}

[dir="ltr"] .taps__serach .Taps {
    margin-left: 39px;

    @media screen and (width<=988px) {
        margin-left: 0px;

    }
}

.taps__serach .active {
    z-index: 1 !important;
}

.offers_ShownCars {

    .p-card {
        width: 100% !important;

        img {
            width: 100% !important;
            // height: 250px;
            // object-fit: contain;
        }
    }

    .p-card-body {
        padding: 0px !important;
        padding-bottom: 20px !important;
        background: #fff;
        border: #FFF !important;
        box-shadow: 0px 3.4456140995025635px 17.228071212768555px 0px rgba(0, 0, 0, 0.08);
    }

    .p-card .p-card-title {
        margin-top: -40px;
        position: relative;
        background: $dark;
        z-index: 0 !important;
        padding: 10px 0 !important;
        text-align: center;
        color: #fff;
        width: 100% !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
    }

    .p-button {
        padding: 3px 10px !important;

        span {
            color: var(--white, #FFF);
            font-family: cairo;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
        }
    }

}

.slider_card {
    padding: 0 15px;

    .p-card .p-card-content {
        padding: 0 !important;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 5px;

        li {
            display: flex;
            gap: 15px;
            flex-direction: row;
            font-family: Cairo;
            align-items: center;
        }

        .oneDay {
            color: $dark;
            /* Body1 */
            font-family: Cairo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 197.4%;
            /* 27.636px */
            letter-spacing: 0.63px;
            opacity: 0.5;
        }
    }

    .order {
        margin-top: -45px;

    }
}

.app__discount {
    color: #F76D57;
    font-family: Cairo;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170.4%;
    /* 20.448px */
    text-decoration-line: strikethrough;
    text-decoration: line-through;
}

.filter_by_price {
    h4 {
        color: var(--dark, #162A2B);
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 197.4%;
        letter-spacing: 0.592px;
    }

    .p-slider .p-slider-handle {
        border: 2px solid $dark;
    }

    .p-slider .p-slider-range {
        background: $dark ;
    }

    .p-slider:not(.p-disabled) .p-slider-handle:hover {
        background: $dark ;
        border-color: $dark ;
    }

    .p-button {
        background-color: $dark;
        border: $dark;

        &:hover {
            background-color: $mainColor !important;

        }
    }
}

.slider_range {
    width: 224px;
    display: flex;
    align-items: center;
    justify-content: space-between !important;

    span {
        color: var(--dark, #162A2B);
        font-family: Cairo;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 197.4%;
        /* 25.972px */
        letter-spacing: 0.592px;
    }
}

[dir="ltr"] .slider_range {
    flex-direction: row;
}

[dir="rtl"] .slider_range {
    flex-direction: row-reverse;
}

.container_offers_ShownCars {
    width: 80% !important;
    margin: auto !important;



    @media screen and (width <=830px) {
        width: 90% !important;

    }

    @media screen and (width <=1025px) {
        width: 90% !important;

    }
}

.IMA_CAR {
    // max-width: 100%;
    // /* تحديد الحد الأقصى للعرض */
    // height:auto; 
    // width: 100%;
    // object-fit: contain;
    // /* جعل الصورة تمتد للعرض الكامل للعنصر الذي يحتوي عليه */
    height: 300px !important;
    object-fit: cover !important;
}

.p-datepicker-calendar {

    td {
        text-align: center;
    }

    tr {
        text-align: center;
    }

}

.p-datepicker table td {
    padding: 0 !important;
}

.p-datepicker-group-container {
    .p-datepicker-header {
        padding: 0px !important;
    }
}

.p-datepicker-touch-ui,
.p-calendar .p-datepicker-touch-ui {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 49vw !important;
    transform: translate(-50%, -50%);

    @media screen and (width < 700) {
        min-width: 100vw !important;

    }
}

.p-slider-handle {
    z-index: 0 !important;
}


.d-none-price-lg {
    display: flex;

    @media screen and (width < 767px) {
        display: none;
    }
}


.d-none-price-sm {
    display: none !important;

    @media screen and (width < 767px) {
        display: flex !important;
    }
}

.lim_km_price {
    background-color: rgba($color: $mainColor, $alpha: 0.2);
    padding: 3px 9px;
    border-radius: 5px;

    span {
        color: $dark; 
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 197.4%;
        /* 27.636px */
        letter-spacing: 0.63px;
    }
}

.error_mess{
    // background-color: rgba($color: red, $alpha: 0.1);

}