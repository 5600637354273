@import '../../../style/varibale';

.app__offers {
    overflow: hidden;

    .app__connect {
        z-index: -1;

        p {
            width: 618.792px;

            @media screen and (width <=767px) {
                width: 90%;
            }
        }
    }

    .offers {

        img {
            border-radius: 20px 20px 0px 0px !important;
            /*       width: 511px !important;
            height: 234px !important; */
        }

        .p-card {
            // width: 511px !important;
            border-radius: 20px 20px 0px 0px !important;
            border: 1px solid rgba(206, 147, 26, 0.15) !important;
            background: linear-gradient(180deg, #B9871C 0%, #162A2B 100%) !important;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05) !important;

            .p-card-title {
                color: var(--white, #FFF);
                text-align: center;

                /* Title */
                font-family: Cairo;

                font-size: 20px !important;
                font-style: normal;
                font-weight: 700 !important;
                line-height: normal;
                margin-bottom: 0px !important;
                z-index: 0 !important;
            }

            .p-card-content {
                padding: 5px !important;

                p {

                    color: var(--white, #FFF);
                    text-align: center;

                    /* Caption */
                    font-family: Cairo;

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 170.4%;
                    /* 20.448px */
                }
            }
        }


    }
}

.btn_offers {
    display: flex;
    justify-content: center;
    align-items: center;

    .p-button {
        background: #D31838 !important;
        border-radius: 20px;
        background: #D31838;
        width: 495px;
        height: 114px;

        @media screen and (width <=767px) {
            width: fit-content;
            height: 90px;

        }

        @media (min-width: 769px) and (max-width: 1024px) {
            width: fit-content;
            height: 90px;

        }

        span {
            color: #FFF;
            font-size: 34px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (width <=767px) {
                font-size: 18px;

            }

            @media (min-width: 769px) and (max-width: 1024px) {
                font-size: 25px;
            }
        }
    }
}

.app__offer_details {

    .countdown__offers {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        display: flex;
        width: 341px;
        min-height: 150px;
        border-radius: 20px 20px 0px 0px;
        justify-content: center;
        align-items: center;
        gap: 9px !important;
        margin-top: - calc(150px / 2);


        .title {
            span {
                color: $dark;
                padding-top: 8px;

            }
        }

        .countdown {
            display: flex;
            flex-direction: row;
            gap: 15px;


            :is(.date, .hours, .minutes, .seconds) h4 {
                border-radius: 3.957px;
                background: #CE931A;
                padding: 35px !important;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 65px;
                height: 58px;
                color: #FFF;
                font-variant-numeric: ordinal;
                font-size: 55.391px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -4.708px;
                letter-spacing: 1px;

                @media screen and (width <=767px) {
                    width: 45px;
                    font-size: 30px;
                    padding: 5.643px 14.767px 0px 14.813px !important;
                }

                @media (min-width: 769px) and (max-width: 1024px) {
                    width: 45px;
                    font-size: 30px;
                    padding: 5.643px 14.767px 0px 14.813px !important;

                }

            }

            :is(.date, .hours, .minutes, .seconds) p {
                text-align: center;
                color: $dark ;
                font-family: Cairo;

                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 170.4%;
                /* 20.448px */
            }
        }

        @media screen and (width <=767px) {
            width: 240.527px;
            padding: 5.643px 14.767px 0px 14.813px;
            margin-top: - calc(150px /3);

        }

        @media (min-width: 769px) and (max-width: 1024px) {
            width: 240.527px;
            padding: 5.643px 14.767px 0px 14.813px;
            margin-top: - calc(150px /3);
        }
    }

    .app__offer_details-content {

        p {
            margin-top: 30px;
            color: $dark ;

            font-family: Cairo;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 250%;

            @media screen and (width <=767px) {
                margin-top: 10px;
                line-height: 150%;

            }

            @media (min-width: 769px) and (max-width: 1024px) {
                margin-top: 10px;
                line-height: 150%;

            }
        }

        ul {

            list-style: disc !important;
            display: flex;
            gap: 8px;
            flex-direction: column;
            margin-top: 30px !important;
        }
    }

}

[dir="rtl"] .app__offer_details-content p {
    text-align: right;
}

[dir="ltr"] .app__offer_details-content p {
    text-align: left;
}

[dir="rtl"] .app__offer_details-content ul {
    padding-right: 20px !important;
}

[dir="ltr"] .app__offer_details-content ul {
    padding-left: 20px !important;
}

.offers_ShownCars { 
    .p-card .p-card-content {
        padding: 0;
    }

    .p-card {
        width: 100% !important;

        img {
            width: 100% !important;
        }
    }

    .p-card-body {
        padding: 0px !important;
        padding-bottom: 20px !important;
        background: #fff;
        border: #FFF !important;
        box-shadow: 0px 3.4456140995025635px 17.228071212768555px 0px rgba(0, 0, 0, 0.08);
    }

    .p-card .p-card-title {
        margin-top: -40px;
        position: relative;
        background: $dark;
        z-index: 0 !important;
        padding: 10px 0 !important;
        text-align: center;
        color: #fff;
        width: 100% !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: normal !important;
    }

    .slider_card {
        padding: 0 15px;

        .p-card .p-card-content {
            padding: 0 !important;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 5px;

            li {
                display: flex;
                gap: 15px;
                flex-direction: row;
                align-items: center;
            }

            .oneDay {
                color: $dark;
                /* Body1 */
                font-family: Cairo;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 197.4%;
                /* 27.636px */
                letter-spacing: 0.63px;
                opacity: 0.5;
            }
        }

        .order {
            margin-top: -45px;

        }
    }
}

.price {
    display: inline-flex;
    height: 29.205px;
    padding: 3.436px 8.59px;
    align-items: center;
    gap: 2.655px;
    flex-shrink: 0;
    border-radius: 33.188px;
    background: #F76D57;
    color: white;
    top: - calc(29.205px /2);
    left: 11px;


    span {
        color: var(--white, #FFF);
        height: 100%;
        display: flex;
        align-items: end;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.app__offers_width {
    width: 70%;

    @media screen and (width <=767px) {
        width: 100%;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        width: 95%;
    }
}

.app__offers_details_width {
    width: 100%;
 
}

.app__offer_page_img{
    width: 100% !important;
    height: 240px !important;
    object-fit: cover;
}


.Player_offers{
    
    .Player{

        width: 25% !important;
        margin: auto;
        margin-top: 30px;
        margin-bottom: 30px;
    
        @media screen and (width < 767px) {
            width: 75% !important;
        }
    }
}