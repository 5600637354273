  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');
 // colors
 // $mainColor: #06103C;
 $mainColor: #CE931A;
 $white: white;
 $dark: #162A2B;
 $secondColor: #FFCC00;
 $footerColor: #24282C;
 $colorGrey-0: #404040;
 $colorGrey-1: #999999;
 $colorDark: #272727FA;
 $colorLight: #F9F9F9;
 $colorLight-1: #F7F9FB;
 $m_navbar: 100px;
 // font size
 $fontSize-16: 16px;
 $fontSize-17: 17px;
 $fontSize-18: 18px;
 $fontSize-20: 20px;
 $fontSize-21: 21px;
 $fontSize-22: 22px;
 $fontSize-23: 23px;
 $fontSize-24: 24px;
 $fontSize-25: 25px;
 $fontBase: 'Cairo', sans-serif;

 // Responsive Variables
 $xxl: "only screen and (min-width: 1601px) and (max-width: 1900px)";
 $xl: "only screen and (min-width: 1601px) and (max-width: 1800px)";
 $laptop: "only screen and (min-width: 1200px) and (max-width: 1600px)";
 $lg: "only screen and (min-width: 992px) and (max-width: 1200px)";
 $md: "only screen and (min-width: 768px) and (max-width: 991px)";
 $xs: "(max-width: 767px)";
 $sm: "only screen and (min-width: 576px) and (max-width: 767px)";

 //Landscape-responsive
 $xs-landscape: "only screen and (min-width: 540px) and (max-width: 767px) and (orientation: landscape)";

 // shadow
 $shadow: 0px 6px 7px rgba(188, 188, 188, 0.16);
 ;
 $shadow-navbar: 0px 4px 4px rgba(183, 183, 183, 0.47);

 // border radius
 $radius-sm: 5px;
 $radius-lg: 10px;


 @mixin flex($display, $justify, $align, $dir) {
     display: $display;
     justify-content: $justify;
     align-items: $align;
     flex-direction: $dir ;

 }

 @mixin size($w, $h) {
     width: $w;
     height: $h;
 }

 /** 
<Col xl={3} lg={4} md={6} sm={12}   className='                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 -danger'>
    <h1>shzloka</h1>
</Col>


.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
}

    const fetchCarsDetailsRef = useRef(fetchCarsDetails);
    const fetchCitiesDataRef = useRef(fetchCitiesData);
    useEffect(() => {
        window.scrollBy(0, -window.pageYOffset);
    }, [])
    useEffect(() => {
        fetchCarsDetailsRef.current();
    }, [id]);
 */

