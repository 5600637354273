@import '../../../style/varibale';


.app__delivery_address {
    padding-top: 80px !important;
    padding-right: 120px;
    padding-left: 120px;
    padding-bottom: 80px !important;

    @media screen and (width <=1000px) {
        padding-right: 0px !important;
        padding-left: 0px !important;
        
    }

    .content {
        display: flex;
        flex-direction: column;
        // align-items: center;
        gap: 15px;

        .address {
            border-radius: 4px;
            background: var(--dark, #162A2B);
            display: flex;
            width: 704px;
            padding: 10px 16px;
            align-items: center;
            gap: 46px;
            @media screen and (width <=1412px) {
            width: 100%;
                
            }
            h3 {
                color: var(--white, #FFF);
                text-align: right;

                /* Body1 */
                font-family: Cairo;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 197.4%;
                /* 27.636px */
                letter-spacing: 0.63px;
            }
        }
    }
}