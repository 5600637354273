@import '../../../style/varibale';

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: $mainColor !important;
}

.p-galleria .p-galleria-indicators {
    background-color: transparent !important;
}

.slider_car_details {

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background-color: $mainColor !important;
        border-radius: 15px;
        width: 15px;
    }

    .p-carousel-indicators {
        background-color: transparent !important;
    }
}

.image__container {
    position: relative;
    width: 100%;
    height: 100vh;

    .image__slider {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: center center;

        @media screen and (width<=900) {
            object-fit: contain;
        }
    }

    .overlay {
        position: absolute;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100% !important;
        background: linear-gradient(180deg, rgba(22, 42, 43, 0.51) 20%, rgba(22, 42, 43, 0.00) 100%);

    }
}

.content__silder {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;


}

.content__silder-rtl {

    display: flex;
    justify-content: center;
    flex-direction: column;

    .content {
        margin-left: auto !important;
        display: flex;
        justify-content: start;
        flex-direction: column;
        text-align: right;
        padding: 15px 50px;

        @media screen and (width <=767px) {
            padding: 15px 10px;
        }

        span {
            text-align: right;

        }

        p {
            text-align: right;

        }

        .link__home {
            width: 100% !important;
            display: flex;
            justify-content: right;
            align-items: center;
        }

    }

}

.app__payment_details {

    .slider_card {
        @media screen and (width <=767px) {
            flex-direction: column !important;

            ul {
                gap: 5px !important;
            }
        }

        ul {
            gap: 15px !important;
        }
    }

    .Calendar_payment {
        @media screen and (width <=767px) {
            margin-top: 20px;
        }

        @media (min-width: 769px) and (max-width: 1024px) {
            margin-top: 20px;

        }

        .p-inputtext {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: none !important;
        }

        .p-calendar-w-btn-right .p-datepicker-trigger {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border: 1.003px solid #D1D1D1;
        }

        .p-button {
            color: $mainColor;
            background: #FFF;
            border: 1.003px solid #D1D1D1;
            border-right: none !important;

            &:hover {
                background: $dark !important;
                border: $dark !important;

            }

        }
    }

    .Calendar_paymentEn {
        @media screen and (width <=767px) {
            margin-top: 20px;
        }

        @media (min-width: 769px) and (max-width: 1024px) {
            margin-top: 20px;

        }

        .p-inputtext {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-right: none !important;
        }

        .p-calendar-w-btn-left .p-datepicker-trigger {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border: 1.003px solid #D1D1D1;
        }

        .p-button {
            color: $mainColor;
            background: #FFF;
            border: 1.003px solid #D1D1D1;
            border-left: none !important;

            &:hover {
                background: $dark !important;
                border: $dark !important;

            }

        }
    }

    .location_icons {
        .p-button {
            background-color: $dark !important;
            border-radius: 10px !important;

        }
    }

    .cal_price {
        display: flex;
        flex-direction: column;


        .price_summary {
            h4 {
                color: $dark;
                /* Title */
                font-family: cairo !important;
                font-size: 16px !important;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }

    :is(.car_back, .btn_pay) .p-button {

        border-radius: 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }

    .btn_pay {

        .p-button {
            color: var(--text, #FAFAFA);
            background-color: $dark !important;
        }
    }

    .car_back {
        .p-button {
            color: $dark;
            background-color: #FFF;

            &:hover {
                background-color: $mainColor !important;
                color: #fff;
                border: $mainColor !important;
            }

        }
    }
}

[dir="ltr"] .btn_pay .p-checkbox .p-checkbox-box {
    width: 19px;
    height: 19px;
    color: #495057;
    border-radius: 4px;
}

[dir="ltr"] .btn_pay label {
    font-size: 15px !important;
}

.Bg_payment {
    top: -70px !important;
    left: 0;
    transform: rotateY(180deg);
    z-index: -1 !important;

    @media screen and (width <=900px) {
        display: none;
    }
}

.contact_us_car_details {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    gap: 15px;

    .email,
    .call {
        display: inline-flex;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 20px;
        background: $dark ;
        cursor: pointer;
    }
}


.app__payment_summary1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .summary-right {
        width: 60px !important;
        height: 60px !important;
    }

    .summary-left {
        width: 100%;

        .content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .content1,
            .content2 {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
            }

            .content1 {
                width: 100%;

                h3 {
                    color: $dark;
                    text-align: right;
                    font-family: cairo;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 197.4%;
                    /* 27.636px */
                    letter-spacing: 0.63px;
                }

                h2 {
                    color: $dark;
                    font-family: Cairo;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 197.4%;
                    /* 27.636px */
                    letter-spacing: 0.63px;
                }
            }

            .content2 {
                h3 {
                    color: var(--Stroke, #ACACAC);
                    text-align: right;

                    /* Body1 */
                    font-family: Cairo;

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 197.4%;
                    /* 27.636px */
                    letter-spacing: 0.63px;
                }

                h2 {
                    color: var(--Stroke, #ACACAC);
                    text-align: right;

                    /* Body1 */
                    font-family: Cairo;

                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 197.4%;
                    /* 27.636px */
                    letter-spacing: 0.63px;
                }
            }
        }
    }
}

.app__payment_summary_date {
    .summary-left {
        width: 100%;


        .content2 {
            width: 100%;

            h3 {
                color: $dark;
                text-align: right;
                font-family: cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 197.4%;
                /* 27.636px */
                letter-spacing: 0.63px;
            }

            h2 {
                color: $dark;
                font-family: Cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 197.4%;
                /* 27.636px */
                letter-spacing: 0.63px;
            }
        }

        .content2 {
            h3 {
                color: var(--Stroke, #ACACAC);
                text-align: right;

                /* Body1 */
                font-family: Cairo;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 197.4%;
                /* 27.636px */
                letter-spacing: 0.63px;
            }

            h2 {
                color: var(--Stroke, #ACACAC);
                text-align: right;

                /* Body1 */
                font-family: Cairo;

                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 197.4%;
                /* 27.636px */
                letter-spacing: 0.63px;
            }
        }

    }
}

.app__payment_summary2 {
    display: flex;
    gap: 5px;
    flex-direction: column;

    .content1 {
        display: flex;
        justify-content: space-between;

        .content1_title {
            span {
                color: $dark;
                text-align: right;

                /* Caption */
                font-family: Cairo;

                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 170.4%;
                /* 20.448px */
            }
        }

        .content1_price {
            h2 {
                color: $dark;
                font-family: Cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 197.4%;
                /* 27.636px */
                letter-spacing: 0.63px;
            }
        }
    }
}

.app__payment_summary3 {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .content2 {
        .content2_title {
            span {
                color: $mainColor;
                font-family: Cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .content2_discount {
            h2 {
                color: $dark;
                font-family: Cairo;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 197.4%;
                letter-spacing: 0.45px;
            }

            h3 {
                color: $dark;
                font-family: Cairo;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    .content3 {
        gap: 8px !important;

        .content3_title {
            span {
                color: $mainColor;
                text-align: right;
                font-family: Cairo;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 170.4%;
                /* 20.448px */
            }
        }

        .content3_price {
            h2 {
                color: $dark;
                font-size: 20Dpx;
                font-style: normal;
                font-weight: 700;

            }
        }
    }
}

.text_done_Reservations {
    color: var(--dark, #162A2B);
    font-family: Cairo;
    font-size: 17.793px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Messages_Ar {

    .p-message.p-message-info {
        background: #e9e9ff;
        border: solid #696cff;
        border-width: 0 6px 0 0px !important;
        color: #696cff;
    }

    .p-message-wrapper {
        display: flex;
        gap: 5px;
    }


    .p-message-wrapper {
        .p-message-close.p-link {
            margin: 0 !important;
            margin-right: auto !important;
        }
    }
}

.app__login-image {
    position: relative;

    img {
        object-fit: cover;
        object-position: 100% 100%;

        @media screen and (width < 990px) {
            display: none !important;
        }
    }

    .btn_back {
        position: absolute;

        top: 57px;
        background: $dark;
        opacity: .8;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 38px;
        filter: drop-shadow(0px 4px 15px rgba(4, 4, 4, 0.16));

        i {
            color: white;
        }
    }
}

.banners_top_100 {
    margin-top: 150px !important;
}

.location_btn {
    .p-button {
        display: flex !important;
        gap: 0 !important;
    }
}

.map_lg_screen {
    display: block;

    @media screen and (width < 990px) {
        display: none;
    }
}

.map_sm_screen {
    display: none;

    @media screen and (width < 990px) {
        display: block;
    }
}

.payment_app {
    span {
        color: var(--dark, #162A2B);
        font-family: Cairo;
        font-size: 17.793px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
.calendar_check{
    .p-calendar {
        height: 43px;
    }
}