@import '../../style/varibale';


.privacy_policy {
    .content {
        ul {
            margin: 25px !important;
            padding: 0 !important;
            list-style: disc !important;
        }

        a {
            color: $mainColor;
        }
    }
}


.terms_popup {
    .content {
        .list_number {
            ul {
                margin: 25px !important;
                padding: 0 !important; 
            }
        }

        a {
            color: $mainColor;
        }
    }
}

.terms_popup .content .list_number ul {
    list-style: auto !important;
}

.terms_popup .content .list_number_Ar ul {
    list-style: arabic-indic !important;
}



.terms_popup .content .list_number ol {
    list-style: auto !important;
}

.terms_popup .content .list_number_Ar ol {
    list-style: arabic-indic !important;
}

