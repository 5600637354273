@import '../../../style/varibale';

.app__aboutus {
    img {
        height: 977px !important;
    }

    .content {
        align-items: flex-start !important;

        .app__title {
            margin-bottom: 50px !important;

            h3 {
                color: white !important;
                font-size: 30px;
                text-align: right !important;

                @media screen and (width <=767px) {
                    font-size: 16px;
                }
            }
        }

        p {

            width: 690px !important;
            color: var(--white, #FFF) !important;
            text-align: right !important;
            font-family: Cairo !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 40px !important;

            @media screen and (width <=767px) {
                width: 90% !important;
                margin: auto;
            }

            @media screen and (width <=600px) {
                width: 95% !important;
                margin: auto;
                font-size: 14px !important;
                line-height: 30px !important;

            }

            span {
                color: var(--Primary, #CE931A);
                font-family: Cairo !important;
                font-size: 16px !important;
                font-style: normal !important;
                font-weight: 400 !important;
                line-height: 40px !important;

            }

        }
    }

}

.app__aboutus {
    .contentAr {
        margin-right: 50px;

        p {
            text-align: right !important;
        }
    }
}

.app__aboutus {
    .contentEn {
        margin-left: 50px;

        p {
            text-align: left !important;
        }
    }
}