@import '../style/varibale';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #EDF2F8;
  font-family: $fontBase !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $mainColor;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: $mainColor, $alpha: 0.8);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem $mainColor !important;
  border-color: $mainColor !important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba($color: $mainColor, $alpha: 0.4) !important;
}

.p-inputtext:enabled:hover {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.1rem rgba($color: $mainColor, $alpha: 0.4) !important;
  border-color: $mainColor !important;

}

.p-button {
  border-color: rgba($color: $mainColor, $alpha: 0.4);
}

.p-button:enabled:focus {
  box-shadow: 0 0 0 0.1rem rgba($color: $mainColor, $alpha: 0.4) !important;
  border-color: $mainColor !important;
}


.p-multiselect,
.p-dropdown {
  background: #ffffff;
  // border: 1px solid $mainColor !important;
}

.p-multiselect:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled):hover {
  box-shadow: 0 0 0 0.1rem rgba($color: $mainColor, $alpha: 0.4) !important;
  border-color: $mainColor !important;
}

.p-multiselect:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba($color: $mainColor, $alpha: 0.4) !important;
  border-color: $mainColor !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: $mainColor;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.2rem rgba($color: $mainColor, $alpha: 0.4) !important;
  border-color: $mainColor !important;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #ffffff;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $mainColor !important;
  background: $mainColor;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: $mainColor !important;
  background: $mainColor !important;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $mainColor !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba($color: $mainColor, $alpha: 0.4) !important;
  border-color: $mainColor;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $mainColor;
  background: $mainColor;
  color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $mainColor;
  background: $mainColor;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: $secondColor;
  background: $secondColor;
  color: #ffffff;
}

a {
  text-decoration: none !important;

}

input::placeholder {
  font-family: Cairo;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  font-family: Cairo;

}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

/* height of the container */
.leaflet-container {
  height: 90vh;
  width: 90%;
  border-radius: 2rem;
}

