@import '../../../style/varibale';

.app__login {
    height: 100vh;
    overflow-x: hidden;

    .app__login-image {
        position: relative;

        img {
            object-fit: cover;
            object-position: 100% 100%;
        }

        .btn_back {
            position: absolute;
            top: 57px;
            background: $mainColor;
            opacity: .8;
            width: 55px;
            height: 55px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 38px;
            filter: drop-shadow(0px 4px 15px rgba(4, 4, 4, 0.5));

            i {
                color: white;
            }


        }
    }

    .app__login-contect {
        padding: 45px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (width<=1190px) {
            padding: 15px;

        }
    }

    .Inputs {
        margin-top: 51px !important;
    }

    label {
        color: var(--dark, #162A2B);

        /* Body1 */
        font-family: Cairo;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 197.4%;
        /* 27.636px */
        letter-spacing: 0.63px;
    }

    .text_box {
        color: var(--dark, #162A2B);

        /* Caption */
        font-family: Cairo;

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 170.4%;
        /* 20.448px */
    }

    .forget_password {
        display: flex;
        justify-content: space-between;

        .p-checkbox .p-checkbox-box {
            width: 20px !important;
            height: 20px !important;
            // background-color:$dark !important;
        }

        .p-checkbox .p-checkbox-box.p-highlight {
            border-color: $dark !important;
            background: $dark !important;
        }

        span {
            color: $mainColor;
            text-align: right;
            /* Caption */
            font-family: Cairo;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 170.4%;
            /* 20.448px */
        }
    }

    .p-button {
        background-color: $mainColor !important;
        border-color: $mainColor !important;
    }

    .app__now_account {
        margin-top: 27px !important;

        span {
            color: $dark;

            /* Body1 */
            font-family: Cairo;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 197.4%;
            /* 27.636px */
            letter-spacing: 0.63px;
        }

        a {
            color: $mainColor;

            /* Body1 */
            font-family: Cairo;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 197.4%;
            letter-spacing: 0.63px;
        }
    }
}

@media screen and (width <=991px) {
    .bg_login_md {
        display: none !important;
    }

    .bg_login_md_view {
        width: 100% !important;
        padding: 0 30px !important;
    }
}

[dir="ltr"] .btn_back {
    right: 70px !important;
}

[dir="rtl"] .btn_back {
    left: 70px !important;
}

.password_open {
    position: relative;

    .app__login-btn-icons {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        cursor: pointer;
        width: 20px !important;

    }

    .p-password {
        width: 100% !important;
    }
}

.btn_login {
    .p-button {
        width: 100% !important;
        display: flex !important;
        justify-content: center !important;

        .p-button-label {
            width: fit-content !important;
        }
    }
}

.p-inline-message {
    padding: 0 !important;
}

.p-inline-message.p-inline-message-error {
    background: none !important;
    border: none !important;
    color: #ff5757;
    display: flex;
    font-size: 12px;
}

.p-inline-message.p-inline-message-success {
    background: none !important;
    border: none !important;

}

[dir="ltr"] .p-inline-message.p-inline-message-error {
    justify-content: start;
}

[dir="rtl"] .p-inline-message.p-inline-message-error {
    justify-content: start;
    gap: 10px;
}

.p-inline-message .p-inline-message-icon.p-icon {
    display: none !important;
}


.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: none !important;
}

.otp_input {
    width: 50% !important;
    margin: auto !important;

    @media screen and (width < 712px) {
        width: 100% !important;
    }

    @media screen and (width < 900px) {
        width: 90% !important;
    }

    .css-tccdxk {

        @media screen and (width < 712px) {
            gap: 12px !important;
        }

    }
}

.p-steps-title {
    @media screen and (width < 767px) {
        font-size: 11px;
    }
}

.activation_style {
    background-image: url('../../../assets/Images/otp.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .app_form {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba($color: #fff, $alpha: 80%);
        width: 40%;
        padding: 20px;
        border-radius: 10px;

        @media screen and (width < 767px) {
            width: 100%;
        }
    }


    .btn_back {
        position: absolute;
        top: 57px;
        background: rgba($color: #162A2B, $alpha: 1);
        opacity: .8;
        width: 55px;
        height: 55px;
        left: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 38px;
        filter: drop-shadow(0px 4px 15px rgba(4, 4, 4, 0.5));

        i {
            color: white;
        }

        @media screen and (width <=700px) {
            top: 40px;
            left: 20px;

        }
    }

    .btn_otp_submit {
        .p-button {
            background-color: $dark !important;
        }

        .p-button:enabled:focus {
            box-shadow: 0 0 0 0.1rem rgba($color: $dark, $alpha: 0.4) !important;
            border-color: $dark !important;
        }
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        background-color: rgba($color: $mainColor, $alpha: 66%) !important;
        border-radius: 10px;
        z-index: 8888 !important;
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        text-align: left;
        position: absolute;
        bottom: 0;
        right: 0;
        top: -5px;
        left: 0;
        margin: 0;
        padding: 0 8px;
        pointer-events: none;
        /* border-radius: inherit; */
        /* border-style: solid; */
        /* border-width: 1px; */
        overflow: hidden;
        /* min-width: 0%; */
        /* border-color: rgba(0, 0, 0, 0.23); */
        border: none !important;

    }

    .contact-container {
        border-radius: 6px;
        padding: 16px;
        max-width: 400px;
        margin: auto;
        margin-top: 10px;
    }

    .contact-message {
        color: #333;
        margin-bottom: 12px;
        text-align: center;
    }

    .contact-info div {
        border-radius: 4px;
        padding: 8px;
        display: flex;
        align-items: center;
    }

    .contact-label {
        color: #555;
        white-space: nowrap;
        font-family: Cairo;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.40799999237060547px;
        text-align: center;
        color: $dark;

    }

    .contact-link {
        font-family: Cairo;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $dark;
    }

    .contact-phone,
    .contact-whatsapp,
    .contact-email {
        svg {

            path {

                fill: $mainColor !important
            }
        }
    }

    .contact-whatsapp {
        svg {
            width: 15px;

            path {

                fill: $mainColor !important
            }
        }
    }

}

[dir="ltr"] .contact-label {
    margin-left: 8px;

}

[dir="rtl"] .contact-label {
    margin-right: 8px;

}

[dir="rtl"] .contact-link {
    margin-right: 10px;
}

[dir="ltr"] .contact-link {
    margin-left: 10px;
}

.login_by_phone {

    .react-tel-input,
    .react-tel-input .form-control {
        height: 49px !important;
    }

}