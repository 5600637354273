@import '../../style/varibale';

.app__connect {
    position: relative;

    img {
        object-fit: cover;
        width: 100% !important;
        height: 100vh;
    }

    .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        h3 {
            color: #FFF;
            text-align: center;
            font-family: Cairo;
            
            font-size: 56px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            color: #FFF;
            text-align: center;
            font-family: Cairo;
            font-size: 20.372px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            
        }
    }

}

@media (max-width: 768px) {
    .app__connect {
        margin-top: 80px;
        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 250px;
        }
        h3 {
            font-size: 26px !important;

        }
        p{
            color: var(--white, #FFF);
            text-align: center;
            font-family: Cairo;

            font-size: 10px !important;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 75%;
        }
    }
    
}

@media (min-width: 769px) and (max-width: 1080px) {
    .app__connect {
        margin-top: 80px;
        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 100%;
        }
        h3 {
            font-size: 36px !important;

        }
        p{
            color: var(--white, #FFF);
            text-align: center;
            font-family: Cairo;

            font-size: 20px !important;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 75%;
        }
    }
}