@import '../../style/varibale';

.navbar-navOnthorPAge {

    background: $dark;
}

.navbar-dark {
    background: $dark;
}



.main-nav {

    nav {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
}

.navbar__social {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: $mainColor !important;
    height: 35px !important;

    &.hide {
        display: none;
    }

    .navbar__social-Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        background-color: $mainColor !important;

        .center {
            display: flex;
            gap: 50px;
            align-items: center;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 1px;
                height: 35px;
                background-color: #1F1F1F;
                left: 35%;
            }

            svg {
                width: 15px !important;

            }

            .nav1__home_svg {
                svg {
                    width: 15px !important;
                }
            }

            .change-lang {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                user-select: none;

                .text {
                    color: $colorLight;
                }

                .lang {
                    path {
                        fill: #fff !important;
                    }
                }
            }
        }

        .center-rtl {
            &::after {
                left: 65% !important;
            }
        }

        .social {
            display: flex;
            gap: 25px;

            @media screen and (max-width:680px) {
                display: none;
            }

            position: relative;

            svg,
            .facebook {
                width: 16px !important;
                height: 16px !important;
            }
        }
    }

}

.Logo__RightClick {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 80px !important;
        position: relative;
        // left:50px;
        right: -28px;
        top: 0px;
        height: calc(80px + 35px);

        @media screen and (max-width :780px) {
            position: relative;
            right: -28px !important;
            top: -51px !important;
            height: 80px !important;
        }


    }

    margin: 0 !important;
    padding: 0 !important;

}

.Logo__RightClickEn {
    img {
        left: -28px;
    }
}

.icons__Close {
    width: 30px;
    cursor: pointer;

    &:hover {
        line {
            transition: all 0.5s;
            stroke: $mainColor;
        }
    }
}

.icons__menu {
    width: 30px;
    cursor: pointer;

    line {
        transition: all 0.5s;
        stroke: $colorLight;
    }

    &:hover {
        line {
            transition: all 0.5s;
            stroke: $mainColor;
        }
    }

    // &:hover {}
}

.icons__Close {
    margin-top: 30px;

    &:hover {
        circle {
            transition: all 0.5s;
            stroke: $mainColor;
        }
    }
}

.app__closeIcons {
    margin-bottom: 50px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.app__menu {
    z-index: 2 !important;
    position: absolute;
    right: 0px;
    top: 0%;
    background: #121212;
    width: 200px;
    overflow: scroll;
    overflow-x: hidden;
    min-height: 100vh;

    @media screen and (max-width:400px) {
        width: 200px;
    }
}

.app__menu-rtl {
    z-index: 2 !important;
    position: absolute;
    left: 0px;
    top: 0%;
    background: #121212;
    width: 200px;
    overflow: scroll;
    overflow-x: hidden;
    min-height: 100vh;

    @media screen and (max-width:400px) {
        width: 200px;
    }
}

.routes_navabr_sidbar {
    display: flex;
    flex-direction: column;
    border-radius: 8px !important;
    padding: 5px 15px;
    // background-color: black;

    .link {
        // background-color: #090f18;
        display: flex;
        justify-content: center;
        flex-direction: row;
        // margin: 10px 0;
        border-radius: 8px !important;
        align-items: center;
        gap: 20px;
        color: #FFFFFF;
        transition: all 0.1s;
        height: 70px;
        width: 100% !important;
        padding: 0px 15px !important;

        .icon {
            path {
                transition: all 0.6s;
            }
        }

        .line {
            path {
                transition: all 0.6s;
                stroke: #0E0E0E
            }
        }

        .link_text {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
        }

        &:hover {
            color: $secondColor !important;

            .icon {
                transition: all 0.5s;

                path {
                    transition: all 0.6s;
                    stroke: $secondColor
                }

                i {
                    color: $secondColor !important;
                }
            }

            .line {
                z-index: 99999999;

                path {
                    stroke: $colorLight !important
                }
            }

            .link_text {
                transition: all 0.5s;
            }

            &::before {
                transition: all 0.6s;
                width: 100%;
            }
        }

    }



    .link.active {
        color: $colorLight !important;
        background-color: $mainColor;

        &::after {
            opacity: 1;
            background-color: white;
        }

        .line {
            path {
                transition: all 0.6s;
                stroke: $colorLight
            }
        }

        .link_text {

            &::before {
                opacity: 1;
                background-color: white;
            }

            &::after {
                opacity: 1;
                background-color: white;
            }
        }
    }
}




.navbar__social {
    transition: all 0.5s;

    &.hidden {
        display: none;
    }
}

.fixed-top {
    transition: all 0.5s;

    &.scroll {
        background: rgba(255, 255, 255, .9) !important;

        top: 0px !important;

        .Logo__RightClick {
            color: $mainColor !important;
        }

        .icons__menu {
            line {
                stroke: $mainColor;
            }
        }

        .app__menu-talk {
            background-color: $mainColor;
            color: $colorLight;

            &:hover {
                background-color: $colorDark;
                color: #FFFFFF;
            }
        }
    }
}

.app__menu-left {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;

    .app__menu-talk {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: $mainColor;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        color: $colorLight;
        border-radius: 4px;
        padding: 6px 15px 6px 20px;
        cursor: pointer;
        user-select: none;
        transition: all 0.5s;
        font-size: 15px;

        &:hover {
            background-color: $colorLight;
            color: $mainColor;
        }

        .spinner-grow {
            width: 10px !important;
            height: 10px !important;
        }
    }
}

.main-nav {
    nav {
        height: 80px !important;
        z-index: 2 !important;

        
    }
}

.navbar-sm-icons-phone {
    path {
        fill: white;
    }
}

@media screen and (width <=600) {
    .Logo__RightClick img {
        position: relative;
        right: -28px !important;
        top: -50px !important;
        height: 102px !important;
    }

    .Logo__RightClick img {
        width: 185px !important;
    }
}


@media screen and (width <=780px) {
    .Logo__RightClick img {
        top: 0px !important;
    }
}

@media screen and (width <=570px) {
    .Logo__RightClick img {
        top: -16px !important;
    }
}




@media screen and (width<=1077px) {
    .navbar__hidden {
        display: none !important;
    }

    .navbar_none {
        display: none !important;
    }

    .navbar_view_lg {
        width: 100% !important;
    }
}

.sidebar_nav {
    display: none;

    @media screen and (width<=1077px) {
        display: block;
    }

    @media screen and (width<=574px) {
        margin-top: -30px !important;
    }
}

.p-sidebar {
    background-color: $dark !important;
}

.p-sidebar-mask {
    z-index: 999 !important;
}

.change_lang-sidebar {
    padding: 0 10px;

    .header {
        h3 {
            color: $mainColor !important;
            font-family: cairo;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

    }

    .app__menu-talk {
        margin-top: 20px;

        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        gap: 15px;
        align-items: center;
        color: #fff;


    }
}

.login {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;

    a {
        display: flex;
        align-items: center;
    }

    padding: 5px 16px;

    h2 {
        color: #fff;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
    }
}


.p-sidebar-mask.p-component-overlay {
    visibility: hidden;

    @media screen and (width <=1077px) {
        visibility: visible;
    }
}

.p-sidebar-mask.p-component-overlay {
    pointer-events: auto;
    z-index: 999999999999999 !important;
}

.details_users_sidebar {
    display: flex;
    gap: 15px;

    img {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        object-fit: cover;
    }

    .details_users_sidebar_content {
        span {
            color: #fff;
        }
    }
}