@import '../../../style/varibale';
.p-menuitem{

}
.map_h {
    iframe {

        height: 100vh !important;
    }
}


.btn_Branches {
    width: 90%;
    width: 100%;
    height: 100vh;
    display: flex;
    margin-top: 100px;
    align-items: start;
    gap: 10px;
    flex-direction: column;


    .p-dropdown {
        background-color: $dark !important;

        span {

            color: white !important;
        }

        .p-dropdown-trigger {
            svg {
                path {
                    fill: #fff !important
                }
            }
        }
    }

    .p-dropdown-items {
        direction: rtl;
    
        .p-highlight {
            background-color: $mainColor !important;
            color: #fff !important;
        }
    }
    
    .title_branches {
        padding-right: 40px;
    }
    
    .app__branches_details {
        h1 {
            color: $mainColor !important;
            font-size: 22px;
        }
    
        ul {
            margin-top: 20px !important;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
    
    .p-menuitem-link,
    .p-panelmenu-header-link {
        display: flex !important;
        justify-content: space-between !important;
        flex-direction: row-reverse !important;
        // color: #fff !important;
    
        span {
            color: #fff !important;
        }
    }
    
    .p-panelmenu-header-link {
        color: #fff !important;
        background-color: $dark !important;
    }
    
    .p-panelmenu-root-submenu {
    
        .p-menuitem-link {
            background-color: $mainColor !important;
            color: #fff !important;
            width: 100% !important;
    
            span {
                color: #fff !important;
    
            }
        }
    }
  .PanelMenu_dir{

      .p-submenu-list :nth-child(n)  {
      
          .p-toggleable-content {
      
              .p-submenu-list {
                  .p-menuitem-link {
                      display: flex !important;
                      justify-content: left !important;
                      flex-direction: row !important;
                      color: $dark !important;
      
                      gap: 10px;
                      background-color: gray !important;
                  }
              }
          }
      }
  }  
  .PanelMenu_dirAr{
    .p-submenu-list :nth-child(n)  {
    
        .p-toggleable-content {
    
            .p-submenu-list {
                .p-menuitem-link {
                    display: flex !important;
                    justify-content: right !important;
                    flex-direction: row !important;
                    color: $dark !important;
    
                    gap: 10px;
                    background-color: gray !important;
                }
            }
        }
    }
  }
}
