@import '../../../../style/varibale';

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: $mainColor !important;
}

.p-galleria .p-galleria-indicators {
    background-color: transparent !important;
}

.image__container {
    position: relative;
    width: 100%;
    height: 100vh;

    .image__slider {
        width: 100%;
        height: 100vh;
        object-fit: fill;
        object-position: center center;

        @media screen and (width<=900) {
            object-fit: cover;

        }
    }
}

.content__silder {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100% !important;

    .content {
        padding: 15px 50px;
        text-align: left;
        width: 50% !important;


        @media screen and (width <=1280px) {
            width: 80% !important;
        }

        @media screen and (width <=780px) {
            width: fit-content !important;
        }

        @media screen and (max-width:464px) {
            padding: 15px;
        }

        span {

            color: var(--white, #FFF);
            font-family: Cairo;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (max-width:767px) {
                font-size: 35px !important;
            }

            @media screen and (max-width:464px) {
                font-size: 20px !important;
            }
        }

        p {
            font-size: 36px;
            line-height: normal;
            color: #FFFFFF !important;
            margin-bottom: 50px;
            text-align: right;
            /* Subheading */
            font-family: Cairo;

            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 2.5rem;

            @media screen and (max-width:767px) {
                line-height: normal;
                margin-bottom: 30px;
                margin-top: 15px;
                font-size: 20px !important;
            }
        }

    }

    .contentAr {
        background: linear-gradient(95deg, rgba(0, 0, 0, 0.00) 0%, rgba(22, 42, 43, 0.63) 100%);
    }

    .contentEn {
        background: linear-gradient(-95deg, rgba(0, 0, 0, 0.00) 0%, rgba(22, 42, 43, 0.63) 100%);
    }
}

.content__silder-rtl {

    display: flex;
    justify-content: center;
    flex-direction: column;

    .content {
        margin-left: auto !important;
        display: flex;
        justify-content: start;
        flex-direction: column;
        text-align: right;
        padding: 15px 50px;

        @media screen and (width <=767px) {
            padding: 15px 10px;
        }

        span {
            text-align: right;

        }

        p {
            text-align: right;

        }

        .link__home {
            width: 100% !important;
            display: flex;
            justify-content: right;
            align-items: center;
        }

    }

}

.link__home_btn_sidebar {
    margin-bottom: 40px;

    .p-button {
        border-radius: 20px;
        background: var(--Primary, #CE931A);
        width: 204px;
        height: 44px;

        span {
            color: var(--white, #FFF);
            text-align: center !important; 
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

@media (max-width: 768px) {
    .image__container {
        margin-top: 80px;
        height: 250px !important;

        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 250px !important;
        }

        .content__silder {
            .content {
                width: 100% !important;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center; 
                flex-direction: column; 
            }

            span {
                font-size: 30px !important;
                text-align: center !important;
            }

            p {
                color: var(--white, #FFF);
                text-align: center !important;
                font-family: Cairo;

                font-size: 16px !important;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                width: 90%;

            }

        }
    }

    .link__home_btn_sidebar {
        margin-bottom: 15px;

        .p-button {
            border-radius: 20px;
            background: var(--Primary, #CE931A);
            width: 150px;
            height: 34px;

            span {
                color: var(--white, #FFF);
                text-align: center;
                font-size: 14px !important;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media (max-width: 500px) {
    .image__container {
        margin-top: 80px;
        height: 250px !important;

        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 250px !important;
        }

        .content__silder {
            .content {
                width: 100% !important;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span {
                font-size: 26px !important;
                text-align: center !important;
            }

            p {
                color: var(--white, #FFF);
                text-align: center !important;
                font-family: Cairo;

                font-size: 8px !important;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: center;
                width: 90%;

            }

        }
    }

    .link__home_btn_sidebar {
        margin-bottom: 15px;

        .p-button {
            border-radius: 20px;
            background: var(--Primary, #CE931A);
            width: 150px;
            height: 34px;

            span {
                color: var(--white, #FFF);
                text-align: center;
                font-size: 14px !important;
                font-style: normal;
                font-weight: 400;
                line-height: normal; 
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1080px) {
    .image__container {
        margin-top: 80px;
        height: 450px !important;

        img {
            /* تعديلات للهواتف المحمولة */
            width: 100%;
            height: 100% !important;
        }

        .content__silder {
            .content {
                padding: 15px;
                padding-right: 25px;
            }

            span { 
            }

            p {
                color: var(--white, #FFF);
                font-family: cairo;
                font-size: 18px !important;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                text-align: center;
                margin-top: 10px;
            }

        }
    }

    .link__home_btn_sidebar {
        margin-bottom: 15px;

        .p-button {
            span {
                text-align: center !important;
            }
        }
    }
}