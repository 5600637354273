@import '../../../style/varibale';

.app__PersonalData {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
    padding-right: 120px;
    padding-left: 120px;

    @media screen and (width <=1000px) {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: self-start;
        flex-direction: row;

        .img {
            width: 170px;
            height: 170px;

            .personal { 
                position: relative;
                img {
                    border-radius: 50%; 
                    object-fit: cover;
                    position: relative;
                    width: 170px  !important;
                    height: 170px !important;
                }  
            }
            .type_normal {  
                img { 
                    border: 4px solid #3d3d3d; 
                }  
            }
            .gold_type {  
                img { 
                    border: 4px solid gold; 
                }  
            }
            .type_silver{  
                img { 
                    border: 4px solid silver; 
                }  
            }
            .type_bronze {  
                img { 
                    border: 4px solid #CD7F32 ; 
                }  
            }


            .gold {
                bottom: -25px;
                left: 50%;
                transform: translateX(-50%);
                img{
                    width: 70px;
                    height: 70px;
                }
            }



        }

        .Edite {
            .p-button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 5px;
                background-color: white;
                color: $mainColor;
                padding: 0;
                margin: 0;
                border: none;

                .p-button-label {
                    color: $dark;
                }
            }
        }
    }

    .info_edit {
        display: flex;
        flex-direction: column;
        /*      justify-content: center;
        align-items: center; */
        gap: 10px;
        width: fit-content;

    }

    .info {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 10px;
        width: fit-content;

        h3 {
            color: #37474F;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        span { 
            text-align: center; 
            /* Caption */
            font-family: cairo;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 170.4%;
            /* 20.448px */
        }
    }

    .info_content {
        width: 700px;

        @media screen and (width <=1000px) {
            width: 100%;

        }

        p {
            color: #37474F;
            font-family: cairo;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 197.4%;
            /* 27.636px */
            letter-spacing: 0.63px;
        }
    }


    .info_contactus {
        .conatent {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 19px;

            .icons {
                /*   svg {
                    path {}
                } */

                i {
                    color: $mainColor;
                    font-size: 20px;
                }
            }

            .header {
                color: $dark;
                text-align: right;
                font-family: Cairo;
                font-size: 20.207px;
                font-style: normal;
                font-weight: 700;
                line-height: 170.4%;
                /* 25.825px */

                @media screen and (width<=767px) {
                    font-size: 16px;

                }
            }

            .body {
                color: var(--dark, #162A2B);
                font-size: 15.155px;

                font-family: Cairo;
                font-style: normal;
                font-weight: 400;
                line-height: 30.311px;
                margin-top: 6px;
                /* 150% */
            }
        }
    }

    .submit {
        .p-button {
            color: #fff;
            border-radius: 20px;
        }

        span {
            color: #fff;
            font-family: cairo;
            font-size: 16px;
        }
    }
}

[dir="rtl"] .app__PersonalData .info_content {
    p {
        text-align: right;
    }
}

[dir="ltr"] .app__PersonalData .info_content {
    p {
        text-align: left;
    }
}

[dir="rtl"] .app__PersonalData .info_contactus .conatent .body {
    text-align: right;

}

[dir="ltr"] .app__PersonalData .info_contactus .conatent .body {
    text-align: left;

}

.ChangeImage { 
    width: 34.119px;
    height: 34px;
    background-color: #37474F;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
}

[dir="ltr"] .ChangeImage {
    bottom: 0%;
    right: 10%;
}

[dir="rtl"] .ChangeImage {
    bottom: 0%;
    right: 10%;
}


.img {
    width: 170px;
    height: 170px;

    .personal { 
        position: relative;
        .load_img {
            border-radius: 50%;
            border: 3px solid $mainColor;
            object-fit: cover;
            position: relative;
        }  
    }

    .gold {
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
    }



}

.text_client_gold{
    color: gold;
    font-size: 16px !important;
}
.text_client_bronze{
    color: #CD7F32;
    font-size: 16px !important;

}
.text_client_silver{
    color: silver;
    font-size: 16px !important;

}
 
