@import '../../../style/varibale';


.app__points {
    padding-top: 80px !important;
    padding-right: 120px;
    padding-left: 120px;
    padding-bottom: 80px !important;

    @media screen and (width <=1412px) {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .points {
        display: flex;
        flex-direction: column;
        gap: 29px;

        span {
            color: $dark;
            font-family: cairo;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .num_points {
            border-radius: 5px;
            padding: 10px 70px;
            justify-content: center;
            align-items: center;
            background-color: $dark;
            color: var(--white, #FFF);
            font-family: cairo;
            font-size: 32.609px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            width: fit-content;
            user-select: none;

        }
    }

    .app__points_pay {
        margin-top: 20px;

        .p-button {
            background-color: transparent !important;
            border-color: transparent !important;
            color: $dark;
            gap: 5px;

            .p-button-icon {
                color: $mainColor;
            }

            &:hover {
                color: $dark !important;

            }

        }
    }

}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $dark !important;
    background: $dark !important;
}

.app__wallet {
    border-radius: 0.5rem 0 0 0.5rem !important;
    flex: 0;

    // @media screen and (width <=990px) {
    //     display: none;
    // }
}

.modal-body {
    padding: 0px !important;
    overflow: hidden !important;
}

.app__payment_getway {
    padding: 15px 30px;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $mainColor !important;
    background: $mainColor !important;
}

.info_after_payment {
    color: #ACACAC;
    text-align: right;

    /* Caption */
    font-family: cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170.4%;
    /* 20.448px */
}

.discount_codes {
    .title {
        display: flex;
        gap: 13px;
        // justify-content: center;
        align-items: center;

        span {
            color: $dark;

            font-family: Cairo;

            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .Card {
        position: relative;

        img {
            border-radius: 18.222px;
            width: 100%;
        }

        .title {
            z-index: 999 !important;
            position: relative;
            background-color: $mainColor;
            width: 100%;
            height: 44.075px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -44.075px;
            border-radius: 0 0 18.222px 18.222px;

            h3 {
                text-align: center;
                color: #FFF;
                font-family: Cairo;

                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 80%;
            display: flex;
            justify-content: space-evenly;
            flex-direction: column;

            .data {
                padding: 10px;
            }

            h5 {
                color: var(--white, #FFF);
                font-family: Cairo;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            p {
                color: var(--white, #FFF);
                font-family: Cairo;

                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 170.4%;
            }
        }
    }

    .discount_points {
        position: absolute;
        top: -16px;

        border-radius: 33.188px;
        background: #F76D57;
        width: fit-content;
        padding: 5px 15px;

        span {
            color: var(--white, #FFF);
            text-align: center;

            /* Body1 */
            font-family: Cairo;

            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 197.4%;
            /* 27.636px */
            letter-spacing: 0.63px;
        }
    }
}

.payment_footer_btn {
    margin-top: 70px !important;

    @media (max-width: 428px) {
        .payment_footer_btn_gap {
            gap: 5px;
        }
    }

    @media (min-width: 769px) and (max-width: 1080px) {
        gap: 5px;
    }

}

.payment_footer_btn2 {
    margin-top: 70px !important;

    @media (max-width: 768px) {
        margin-top: 10px !important;

    }

    @media (min-width: 769px) and (max-width: 1080px) {
        margin-top: 20px !important;

        gap: 15px;

    }

}

[dir="rtl"] .app__points .discount_codes .cursor-pointer .Card .content .data {
    background: linear-gradient(270deg, #162A2B 15.82%, rgba(22, 42, 43, 0.00) 100%);

    p,
    h5 {
        text-align: right;
    }
}

[dir="ltr"] .app__points .discount_codes .cursor-pointer .Card .content .data {
    background: linear-gradient(-270deg, #162A2B 15.82%, rgba(22, 42, 43, 0.00) 100%);

    p,
    h5 {
        text-align: left;
    }
}


[dir="rtl"] .app__points .discount_codes .cursor-pointer .Card .discount_points {
    left: 0;
}

[dir="ltr"] .app__points .discount_codes .cursor-pointer .Card .discount_points {
    right: 0;
}