@import '../../../../style/varibale';

.app__service-home {
    .content {
        margin-top: 20px;

        h1 {
            color: var(--dark, #162A2B);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            color: var(--dark, #162A2B);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 197.4%;
            letter-spacing: 0.63px;
        }
    }
}

.app__service2-home {
    background-color: $mainColor;
    padding-top: 65px;
    padding-bottom: 65px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;

    h1 {
        color: #FFF;
        text-align: center;
        font-family: Cairo;

        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 534px;
        @media screen and (width<=540px) {
            width: 95%;
        font-size: 24px;

        }
    }

    p {
        color: #FFFCF8;
        margin-top: 40px;
        font-family: Cairo;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.65;
        @media screen and (width<=540px) {
           text-align: center;
        }
    }
}

.groups_btn-input {
    width: fit-content;
    background-color: #FFF;
    padding: 5px;
    display: flex;
    flex-direction: row;
    border-radius: 6.051px;
    width: 430.5px;
    height: 52.647px;
    flex-shrink: 0;

    @media screen and (width<=767px) {
        width: 60%;
    }
    @media screen and (width<=540px) {
        width: 80%;
    }

    input {
        border-radius: 0 !important;
        border-color: white !important;
    }

    .p-button {
        background-color: $dark !important;
        width: 120.75px !important;
        height: 40.544px !important;
        flex-shrink: 0 !important;
        
    }
}