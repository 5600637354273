@import '../../../style/varibale';

.card_jobs {
    padding: 25px !important;
    border-radius: 8px;
    border-color: transparent !important;
    background: rgba(255, 255, 255, 0.60) !important;
    box-shadow: 0px 30px 60px 0px rgba(0, 26, 255, 0.10);

    .header {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .header_img {
            img {
                width: 50px;
                height: 50px;
            }
        }

        .content {
            display: flex;
            gap: 0;
            flex-direction: column;

            h2 { 
                color: $dark;
                text-align: right;
                font-family: Noto Sans Arabic;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            span {
                color: rgba(0, 0, 0, 0.60); 
                /* Caption */
                font-family: Cairo;

                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                /* 20.448px */
            }
        }
    }

    .content_dec {
        margin-top: 20px;

        p {
            color: rgba(0, 0, 0, 0.60);

              font-family: Cairo;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 20px;
        }
    }

    .btn__group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
            .p-button {
                display: flex;
                width: 119px;
                padding: 5px 15px;
                justify-content: center;
                align-items: flex-start;
                gap: 8px;
                border-radius: 100px;
                background: $mainColor;

                /* Caption */
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 170.4%;
                /* 20.448px */
            }
        }

        .btn2 {
            .p-button {

                display: flex;
                padding: 5px 15px;
                align-items: flex-start;
                gap: 8px;
                border-radius: 100px;
                background: rgba(0, 0, 0, 0.07);
                border: rgba(0, 0, 0, 0.07);
                color: $dark;

                /* Caption */
                font-family: Cairo;

                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 170.4%;
                /* 20.448px */
            }
        }
    }
}

.app__apply_jop {
    padding: 10px 15px;

    .p-button {
        padding: 5px 10px !important;
        border: $mainColor;
        background-color: $mainColor !important;

        span {
            font-weight: 400;
            font-family: cairo;
            font-size: 14px !important;
        }
    }

    .p-button-danger {
        
        background: rgba(239, 68, 68, 0.04) !important;
        color: #EF4444 !important;
        border-color: transparent !important;
    }
}

.app__apply_jop_submit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    .p-button {
        width: 70% !important;
        background-color: $dark !important;
        border-radius: 20px !important;

        span {
            padding: 7px 0 !important;
            font-family: cairo;
            font-size: 18px !important;
        }

    }
}