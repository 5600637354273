@import '../../../style/varibale';

.app__register { 

    .app__now_account { 

        .app__now_account_sign_in {
            color: $mainColor;
            cursor: pointer;
        }
    }

}
.react-tel-input,
.react-tel-input .form-control{
    height: 42px !important;
}

.form-control { 
  
    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus {
        box-shadow: 0 0 0 0.1rem rgba($color: $mainColor, $alpha: 0.4) !important;
        border-color: $mainColor !important;

 
    }
}
  
.app__Redister-contect {
    display: flex;
    margin: auto;
    padding: 27px 50px !important;

}

@media screen and (width <=991px) {
    .bg_login_md {
        display: none !important;
    }

    .bg_login_md_view {
        width: 100% !important;
        padding: 50px 50px !important;
    }
}

.input__Col {
    @media screen and (width <=500px) {
        width: 100% !important;

    }
}

 

.p-dialog-header {
    display: none !important;
}
 
@media (max-width: 640px) {
    .p-dialog {

        width: 95vw;
    }
}
 
@media (min-width: 641px) and (max-width: 960px) {
    .p-dialog {
        width: 80vw;
    }
}
.p-dialog {
    background-color: rebeccapurple !important;
}
@media (min-width: 961px) {
    .p-dialog {
        width: 75vw;
    }
}

.p-dialog-content{
    padding: 0px !important;
    
 
} 
.p-dialog-content::-webkit-scrollbar {
    width: 0px;
}

.p-dialog-content::-webkit-scrollbar-thumb {
    background-color: transparent; 
}

.p-dialog-content::-webkit-scrollbar-track {
    background-color: transparent; 
}

.p-dialog .p-dialog-content:last-of-type { 
    border-radius: 6px;
}

.forget_password{
    .p-inline-message.p-inline-message-error {
         color: red !important;
    }
}

.p-password-input{
    width: 100%;
}

.terms_popup{
    ul{
        list-style:  decimal-leading-zero !important ;
    }
}

.text-sm-re {
    a{
        @media screen and (width < 767px) {
            
            font-size: 10px;
        }
    }
}