 @import '../../style/varibale';

 .main-container {
     display: flex;
     z-index: 9999999999999999999999999999;

     @media screen and (max-width:900px) {
         display: none;
     }

     main {
         padding: 10px;
     }
 }

 main {
     background-color: white !important;
 }

 /* Sidebar */
 .sidebar {
     color: white;

     // background-color: $colorDark;
     .top_section {
         display: flex;
         align-items: normal;
         justify-content: center;
         flex-direction: column;
         gap: 20px;
         padding: 7px 18px;

         // .logo {
         //     font-size: 18px;
         //     line-height: 0;
         //     padding: 30px 60px 0px 60px;

         // }
         .logo {
             font-size: 18px;
             line-height: 0;
             width: 129px !important;
             display: flex;
             justify-content: center;
             align-items: center;
             flex-direction: column;
             color: $mainColor;
             -webkit-user-select: none;
             user-select: none;
             margin: auto;
         }

         .bars {
             cursor: pointer;
             color: $colorLight;
         }
     }
 }

 .routes {
     margin-top: 15px;
     display: flex;
     flex-direction: column;
     gap: 10 px;

     .link {
         display: flex;
         color: $colorLight;
         gap: 16px;
         padding: 10px 13px;
         text-decoration: none;
         border-right: 4px solid transparent;
         transition: all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
         cursor: pointer;
         border-radius: 5px;
         align-items: center;

         &:hover {
             // border-left: 4px solid $secondColor;
             transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
             color: $secondColor;
             position: relative;

             .logoSvg path {
                 stroke: $secondColor;
                 transition: fill 0.2s ease-in-out;
             }



         }

         .link_text {
             white-space: nowrap;
             text-decoration: none !important;
             text-decoration: underline;
             font-family: $fontBase;
             font-style: normal;
             font-weight: 400;
             font-size: 15px;
             line-height: 30px;
             // color:;
         }

         // transition: all 1s !important;
         width: 0% !important;
     }

     .active {
         width: 100% !important;
         width: 20px;
         position: relative;
         background-color: $mainColor;
         color: #fff !important;



         .logoSvg:hover path {
             fill: #fff !important;
         }

     }

     .menu {
         display: flex;
         color: white;
         // padding-right: 20px;
         transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
         justify-content: space-between;
     }

     .menu_item {
         display: flex;
         gap: 10px;
     }

     .menu_container {
         display: flex;
         flex-direction: column;

         .active {
             background-color: $dark !important;
             width: 20px;
             position: relative;

         }

         margin-top: -12px;

         .link {
             display: flex;
             color: $colorLight;
             gap: 10px;
             text-decoration: none;
             transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);

             &:hover {
                 transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                 color: $mainColor;
                 position: relative;
             }
         }
     }

     .menu_container .link {
         padding-left: 20px;
     }
 }


 .routesAr {

     .link {
         &:hover {
             // border-left: 4px solid $mainColor;
             transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
             color: $secondColor;
             position: relative;


         }

         // transition: all 1s !important;
         width: 0% !important;
     }

     .active {
         // transition: all 1s !important;

         // background-color: #faa9402f;
         width: 100% !important;
         color: #fff !important;


     }

     .menu_container {



         .link {
             display: flex;
             color: $colorLight;
             gap: 10px;
             text-decoration: none;
             transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);

             &:hover {
                 transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
                 color: $secondColor;
                 position: relative;


             }
         }
     }

 }







 .side {
     height: 100%;
     width: inherit;
     position: fixed;
     top: 0;
     // left: 0; 
     background: linear-gradient(180deg, #162A2B 30%, rgba(206, 147, 26, 0.91) 100%);

     z-index: 99;
     overflow: scroll;
     overflow-x: hidden;
     // display: none;
 }

 .react-tooltip {
     z-index: 999999999999999 !important;
 }

 .main-container_nav {
     display: none;
 }

 @media screen and (max-width:900px) {

     .main-container_nav {
         display: block !important;
     }

     .adminLayout {
         width: 100% !important;
     }
 }

 .active .logoSvg path {
     display: block;
     stroke: #fff !important;
     transition: fill 0.2s ease-in-out;
     width: 40px !important;
 }

 .active .logoSvg:hover path {
     stroke: $mainColor !important;
 }

 .logout {
     .active {
         background-color: transparent !important;
     }

     .change_lang-sidebar {
         padding: 0px 14px !important;
     }
 }

 .username_sidebar {
     text-align: center;
     font-size: 16px;
 }

 .logout {
     a {

         color: white !important;

         &:hover {
             color: rgba($color: $mainColor, $alpha: 0.8) !important;
         }
     }
 }
 .text_manage{
    color: #fff !important;
    &:hover{
        color: $secondColor !important;

    }
 }