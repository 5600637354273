@import '../../../../style/varibale';

// Blog Section 

.blog_section { 

    a {
        font-family: Cairo;
        font-size: 18px;
        font-weight: 600;
        line-height: 46.85px;
        color: $dark;
    }

    .ant-card {
        .ant-card-body {
            padding: 0 !important;
        }
    }

    .header_blog {
        position: relative;

        .blog_category {
            position: absolute;
            left: 30px;
            top: 30px;
        }
    }

    .blog_card {
        background-color: transparent;
        border-radius: 10px;
        border: none;
        color: white;
        // padding: 20px;
        transition: transform 0.3s ease;

        &:hover {
            transform: scale(1.05);
        }

        .blog_image {
            width: 100%;
            height: 282px !important;
            object-fit: cover;
            // border-radius: 10px;


        }

        .blog_category {
            padding: 5px 10px;
            color: white;
            display: inline-block;
            font-size: 14px;
            padding: 3px 8px;
            border-radius: 3px;
            background: rgba(0, 91, 246, 1);

        }

        .blog_meta {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: #ccc;

            .blog_date {
                margin-right: 0px;
                color: rgba(108, 117, 125, 1);

            }

            .blog_icon {
                margin-right: 5px;
            }
        }

        h3 {
            color: white;
            font-size: 20px;
            margin-bottom: 10px;
        }

        p {
            color: #ccc;
            font-size: 14px;
        }
    }
}



.rtl {

    .blog_section {

        padding: 20px;
        width: 95%;
        margin: auto;

        .header_blog {
            position: relative;

            .blog_category {
                position: absolute;
                right: 30px;
                top: 30px;
                width: fit-content;
            }
        }

    }
}

.blogs_data {
    h3 {
        font-family: cairo;
        font-size: 21.2px;
        font-weight: 700;
        line-height: 21.16px;

        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: $dark !important;
    }

    p {
        font-family: cairo;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        text-underline-position: from-font;
        color: rgba(22, 42, 43, 1) !important;

    }
}

.blog_image {
    width: 100%;
    height: 24px !important;
    object-fit: contain; 
    
}


.blog_meta_a{
    .card-body{ 
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}