@import '../../../style/varibale';

.app__booking {
    padding-top: 80px !important;
    padding-right: 120px;
    padding-left: 120px;
    padding-bottom: 80px !important;

    @media screen and (width <=1285px) {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    @media screen and (width <=1274px) {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}

@media screen and (width <=429px) {
    .app__booking_xs {
        .p-button {
            padding: 5px !important;
            margin: 0 !important;
            margin-top: 15px !important;

            span {
                font-size: 10px !important;
            }
        }

        .oneDay {
            margin-right: 0 !important;
        }
      .p-card .p-card-content,
        .app__cars_slider .p-card .p-card-content {
            padding: 0 !important;
            padding-bottom: 40px !important;
        }
    }
    .app__points_xs{
        .title{
            h3{
                font-size: 14px !important;

            }
        }
        .content{
            width:100% !important;
            h5{
                font-size: 16px !important;
            }
        }
        .discount_points span{
            font-size: 10px !important;

        }
    }
}

.outlined{
    .p-button{
        background: transparent !important;

    }
}