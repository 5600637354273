@import '../../../../style/varibale';

.app__download {
    overflow: hidden;

    .app__mabile {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../../../assets/Images/AppBg.png');
        background-repeat: no-repeat;
        background-position: center center;
        object-fit: contain;

        @media screen and (width<=767px) {
            display: none;
        }

    }

    .app__mabile_sm {
        display: none;
        justify-content: center;
        align-items: center;
        object-fit: contain;

        @media screen and (width<=767px) {
            display: flex;

        }
    }


    .app__mabile-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            color: var(--primary, #CE931A);
            font-family: Cairo;
            font-size: 45px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media screen and (width<=990px) {
                font-size: 35px;
                text-align: center;
            }
        }

        p {
            color: var(--dark, #162A2B);


            /* Subheading */
            font-family: Cairo;

            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media screen and (width<=990px) {
                font-size: 16px;
                text-align: center;
            }
        }

        .btn__group {
            display: flex;
            gap: 5px;

            @media screen and (width<=990px) {
                justify-content: center;
            }

            img {
                width: 163.16px;
                height: 47.2px;
                cursor: pointer;
            }
        }
    }
}

[dir="ltr"]  .app__mabile-content {
    text-align: right;

}

[dir="rtl"] .app__mabile-content {
    text-align: left;
}

 