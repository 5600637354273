@import '../../style/varibale';

.loaders {
    position: relative;

 
    .app__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999999999999999999 !important;
        background-color: rgba($color: #fff, $alpha: 1);
        height: 100vh;



        h1 {
            color: var(--dark, #162A2B);
            font-family: cairo;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 4px;

            @media screen and (width <767px) {
                font-size: 30px;
                letter-spacing: 2px;

            }
        }

        img {
            // animation: fadeInOut 4s linear infinite;

            @media screen and (width <767px) {
                width: 130px;
            }
        }

    }
}

@keyframes fadeInOut {
    0% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
  }