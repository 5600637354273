@import '../../../style/varibale';


.btn_2 {
    .p-button {
        background-color: $dark !important;
    }
}

.app__content-social {

    .branch,
    .email,
    .phone {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 15px;

        .content {
            display: flex;
            display: 0;
            flex-direction: column;

            h3 {
                color: $dark;

                font-family: Cairo;

                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            span {
                color: $dark;
                font-family: Cairo;

                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    .email {
        margin-top: 30px;
    }

    .social {
        margin-top: 30px;
        display: flex;
        gap: 25px;

        @media screen and (max-width:680px) {
            display: none;
        }

        position: relative;

        svg,
        .facebook {
            background-color: $mainColor !important;
            padding: 3px;
            border-radius: 4px;
            width: 20px !important;
            height: 20px !important;
        }
    }

    .branch {
        margin-top: 30px;

        svg {
            path {

                fill: $mainColor !important
            }
        }
    }
}

.app__content-social .branch .branches {
    svg path {
        fill: none !important;
    }
}

[dir="rtl"] .app__content-social .branch .content h3,
.app__content-social .branch .content span {
    text-align: right;
}

[dir="ltr"] .app__content-social .branch .content h3,
.app__content-social .branch .content span {
    text-align: left;
}

[dir="ltr"] .app__content-social {
    padding-left: 30px;

    @media screen and (width <=600px) {
        padding-left: 5px;

    }
}

[dir="rtl"] .app__content-social {
    padding-right: 30px;

    @media screen and (width <=600px) {
        padding-right: 5px;

    }
}